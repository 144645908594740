import { getCookie, getUrlQueryParams, setCookie } from '@/core/utils';
import { useRouter } from 'next/router';
import React, { createContext, useContext, useState } from 'react';
import { SESSION_KEY } from './session-storage';
import { AppContextState, SKIN, THEME } from './types';

const initialState: AppContextState = {
  isLogin: null, // 判断未登录，应该使用if(isLogin === false) 而不是if(!isLogin){}
  token: null,
  locale: 'en',
  theme: THEME.LIGHT,
  skin: SKIN.DEFAULT,
};

const AppContext = createContext(initialState);
export const useAppContext = () => useContext(AppContext);
export let appContextSetState = (nextState: object) => {};

type CookieDetails = {
  value: string; // URL参数的键
  days?: number; // cookie的有效期（天数），这是一个可选属性
};

const cookies: { [key: string]: CookieDetails } = {
  ru: { value: 'ru', days: 365 },
  f: { value: 'f', days: 365 },
  AF_DATA: { value: 'afdata' },
  TOKEN: { value: 'tokenFromUrl', days: 1 },
  SOURCE: { value: 'source' },
  CLAC: { value: 'clac' },
};

export const AppProvider = ({ children, auth }: { children: React.ReactNode; auth: boolean }) => {
  const initFunc = () => {
    const tokenFromUrl = getUrlQueryParams('token');
    // 循环设置cookie
    for (const [cookieName, cookieDetails] of Object.entries(cookies)) {
      const paramValue = getUrlQueryParams(cookieDetails.value);
      if (paramValue) {
        setCookie(cookieName, paramValue, cookieDetails?.days);
      }
    }
    const tokenFromCookie = getCookie('TOKEN');
    const token = tokenFromUrl || tokenFromCookie;
    const locale = document.documentElement.getAttribute('lang') || 'en';
    const isLogin = !!token;
    const theme = document.documentElement.getAttribute('theme') as THEME;
    return { token, locale, isLogin, theme };
  };

  const [state, _setState] = useState<AppContextState>(initialState);
  const setState = (appContextSetState = (nextState: object) => _setState({ ...state, ...nextState }));
  const router = useRouter();

  if (typeof document === 'undefined') {
    React.useLayoutEffect = React.useEffect;
  }

  React.useLayoutEffect(() => {
    const { token, locale, isLogin, theme } = initFunc();
    setState({ isLogin, token, locale, theme });
    console.log('token: ', token);

    if (auth && !token) {
      const pathname = router.asPath;
      sessionStorage.setItem(SESSION_KEY.LOGIN_REDIRECT, pathname);
      window.location.replace(`/${locale}/login`);
    }
  }, [state.isLogin, auth]);

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};
